import { create } from "zustand";

export const useModalStore = create((set) => ({
  type: null,
  isShow: false,
  selectedData: null,
  setType: (proos) => set((state) => ({ ...state, type: proos })),
  setIsShow: (proos) => set((state) => ({ ...state, isShow: proos })),
  selectData: (props) => set((state) => ({ ...state, selectedData: props })),
}));
