import moment from "moment";


const validation = {};

const fileTypes = {
    'SUBJECT_INFO': ['SUBJNO', 'AGE', 'SEX', 'STATUS', 'SURVIVAL'],
    'SUMMERY': [],
    'MEDICAL_HISTORY': [],
    'TREATMENT': [],
    'LAB-PATHO': [],
    'RESPONSE': [],
    'SAFETY': [],
}
Object.freeze(fileTypes);



const cellClassRules = {
    // 'cell-fail': params =>  validation.checkRow(params.value, params.colDef.field) || !params.value
}


validation.checkRow = (params, colId) => {
    if (colId === 'AGE') {
        return Number(params) < 3;
    }
    if (colId === 'SEX') {
        return (params !== 'Male' && params !== 'Female');
    }
}

validation.checkCol = (projectInfo, colData) => {

    let tmp;
    Object.keys(fileTypes).filter(v => {
        if (v === projectInfo.fileType) {
            tmp = fileTypes[v];
        }
    })

    const equals = tmp ? colData.length === tmp.length && colData.every((v, i) => v === tmp[i]) : null;

    if (true) {
        return colData.map((v, index) => ({field: v, flex: 1, minWidth: 200, editable: true, resizable: true, cellClassRules: cellClassRules, cellDataType: 'text'}));
    }

    return false;
}

export default validation;