
import qs from "qs";
const _localStorage = {}   
 
_localStorage.setItem = (key, value) => {
 localStorage.setItem(key, 
    qs.stringify(value))
}

_localStorage.getItem = (key) => {
  const value = localStorage.getItem(key)
  return (value) ? qs.parse(value) : null
}

_localStorage.removeItem = (key) => {
  localStorage.removeItem(key)
}


 
export default _localStorage  
