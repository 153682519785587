import { useNavigate } from 'react-router-dom';
import { auth } from '../libs';

export const useCheckPermission = () => {
    const navigate = useNavigate();

    const checkPermission = async () => {
        if (auth.isLogin()) {
            const chkUser = await auth.getUser(auth.getAccessToken().token);
            if (!chkUser.chkPW) {
                navigate('/auth/set-pw', { replace: true, state: chkUser });
            }
        } else {
            alert('권한이 없습니다.');
            navigate(`/auth/login?redirect=${window.location.pathname}`, { replace: true });
        }
    };

    return { checkPermission };
};

