import { useLocation, useNavigate } from "react-router-dom";
import { useModalStore } from "stores";

export const useModal = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const type = useModalStore((state) => state.type);
  const isShow = useModalStore((state) => state.isShow);
  const selectedData = useModalStore((state) => state.selectedData);
  const setType = useModalStore((state) => state.setType);
  const setIsShow = useModalStore((state) => state.setIsShow);
  const selectData = useModalStore((state) => state.selectData);

  const openModal = (data = null) => {
    navigate(pathname + "?isShowModal=true");
    setType(data?.type);
    setIsShow(true);
    selectData(data);
  };

  const closeModal = () => {
    navigate(-1);
    setType(null);
    setIsShow(false);
    selectData(null);
  };

  const resetModal = () => {
    setType(null);
    setIsShow(false);
    selectData(null);
  };

  const selectDataModal = (data) => {
    selectData(data);
  };

  return {
    typeModal: type,
    isShowModal: isShow,
    selectedDataModal: selectedData,
    openModal,
    closeModal,
    resetModal,
    selectDataModal,
  };
};
