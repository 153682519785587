import { usePopupStore } from "../stores";

export const usePopup = () => {
  const isShow = usePopupStore((state) => state.isShow);
  const selectedData = usePopupStore((state) => state.selectedData);
  const setIsShow = usePopupStore((state) => state.setIsShow);
  const selectData = usePopupStore((state) => state.selectData);

  const openPopup = (data = null) => {
    setIsShow(true);
    selectData(data);
  };

  const closePopup = () => {
    setIsShow(false);
    selectData(null);
  };

  const selectDataPopup = (data) => {
    selectData(data);
  };

  return {
    isShowPopup: isShow,
    selectedDataPopup: selectedData,
    openPopup,
    closePopup,
    selectDataPopup,
  };
};
