import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

import backend from './backend';
import _localStorage from './_localStorage';

const auth = {};

auth.defaultRedirectURL = '/admin';

auth.login = async (redirectURL, data) => {
  const tokenJSON = await _getTokenApi(data);
  const token = tokenJSON.token;

  let ret = { status: 'success', errorCode: null, redirectURL: '/main', changePw: false };
  if(token.status) {
    ret.status = 'error';
    ret.errorCode = tokenJSON.data.status;
    ret.message = tokenJSON.data.message;
  } else {
    let accessToken = {
      token: token,
      exp: _parseTokenData(token).exp,
    };

    localStorage.setItem('tokenJSON', JSON.stringify(tokenJSON));
    _localStorage.setItem('accessToken', accessToken);
  
    auth.checkToken(accessToken);
  }

  return ret;
};

auth.logout = async () => {
  _localStorage.removeItem('accessToken');
  //Server Logout 처리
  const ret = await _logoutApi();

  return ret;
};

auth.isLogin = () => {
  if (auth.getAccessToken() == null) {
    return false;
  }
  return true;
};
//_localStorage에서 유효한 accessToken값 취득
auth.getAccessToken = () => {
  let token = _localStorage.getItem('accessToken');
  
  if (!auth.checkToken(token)) return null;
  return token;
};

//유효시간 check
auth.checkToken = (token) => {
  if(token){
    if (token.token && token.exp && dayjs().isBefore(dayjs.unix(token.exp)))
      return true;
  }
    
  
  return false;
};

auth.getUserInfo = (token) => {
  let info = _parseTokenData(token);

  let ret = null;
  if(info){
    ret = info;
  }

  return ret;
}

auth.getUser = (token) => {
  return _checkTokenApi(token);
}


/**
 ******************************
 * @PRIVATE_METHOD
 ******************************
 */

export function _parseTokenData(token) {
  let tokenData = {};

  try {
    tokenData = jwt_decode(token);
  } catch (error) {
    _checkTokenApi(token)
    throw new Error(error);
  }

  return tokenData;
}


//Server로부터 jwt-token 검증
function _checkTokenApi(data) {
  //LOGIN
  if (data) {
    return new Promise((resolve, reject) => {
      backend
        .get('/auth/chk-jwt', {headers: {Authorization: data}})
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          if (e.data) resolve(e.data);
          else reject(e.data);
        });
    });
  }
}

//Server로부터 accessToekn 취득
function _getTokenApi(data) {
  //LOGIN
  if (data) {
    return new Promise((resolve, reject) => {
      // axios.post('http://localhost:3030/login',data
      //    )
      //     .then(res => {});

      backend
        .post('/auth/login', data)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          alert(e.data.message);
          resolve(e);
        });
    });
  }
}

//Server로부터 accessToekn 삭제
function _logoutApi() {
  //LOGOUT
  return new Promise((resolve, reject) => {
    backend
      .post('/logout-jwt')
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        if (e.data) resolve(e.data);
        else reject(e.data);
      });
  });
}

export default auth;
